<template>
    <div>
        <el-dialog :visible.sync="sta.show">
            <span slot="title">详情</span>
            <div class="row">
                <div class="col-md-12">
                    <wgrid :data="grid.ls" v-loading="grid.loading">
                        <el-table-column prop="" label="序号" type="index" width="100" align="center" />
                        <el-table-column prop="CONF_NAME" label="名称" width="100" align="center" />
                        <el-table-column prop="FREQUENCY_TXT" label="周期" width="60" align="center" />
                        <el-table-column label="创建日期" align="center" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.CREATED_DT | datetime('YYYY-MM-DD') }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="Safe_Class_Name" label="巡检类别" width="150"
                            align="center"></el-table-column>
                        <el-table-column label="巡检内容" width="238" align="center">
                            <template slot-scope="scope">
                                <div>{{ scope.row.NORMAL_MESSAGE }}</div>
                                <div>{{ scope.row.DESCRIPTION }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="LOCA" label="位置" width="100" />
                        <el-table-column prop="RET_TXT" label="状态" width="60" align="center" />
                    </wgrid>
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Wgrid from "@/components/wgrid";
export default {
    components: { Wgrid },
    data() {
        return {
            sta: {
                show: false,
                loading: false
            },
            model: {
                TemplateGuid: ''
            },
            grid: {
                sea: {
                    PAGE_SIZE: 12,
                    PAGE_INDEX: 0,
                    PARENT_ID: 0
                },
                ls: [],
                total: 0,
                loading: false,
            }
        }
    },
    methods: {
        init(model) {
            this.sta = { show: true, loading: false }
            this.model = JSON.parse((JSON.stringify(model)));
            this.getType()
        },
        getType() {
            let self = this;
            this.whale.remote.getCollection({
                url: '/api/School/SAFE/RecApi/GetList ',
                data: { CONF_ID: self.model.ID },
                completed(its) {
                    console.log('its', its)
                    self.grid.ls = its
                }
            })
        },
    }
}
</script>