<template>
    <div>
        <el-dialog :visible.sync="sta.show">
            <span slot="title">提醒时间设置（每日安全巡检）</span>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>巡检前提醒时间</b></label><br />
                        <el-time-select v-model="PREVIEW" :picker-options="{
            start: '05:00',
            step: '00:15',
            end: '23:00'
        }" placeholder="选择时间">
                        </el-time-select>
                    </div>
                    <div class="form-group">
                        <label><b>过期未巡检提醒时间</b></label><br />
                        <el-time-select v-model="AFTER" :picker-options="{
            start: '05:00',
            step: '00:15',
            end: '23:00'
        }" placeholder="选择时间">
                        </el-time-select>
                    </div>
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sta: {
                show: false,
                loading: false
            },
            model: {},
            PREVIEW: '',
            AFTER: '',
            check: true
        }
    },
    methods: {
        init() {
            this.sta = { show: true, loading: false }
            let self = this;

            this.sta.loading = true;
            this.whale.remote.getResult({
                url: "/api/School/FX/DictApi/getByName",
                data: { NAME: "DAY_NOTICE", TYPE: "SAFE" },
                finally() { self.sta.loading = false; },
                completed: function (its) {
                    console.log('545', its.DATA)
                    if (its.DATA) {
                        its.DATA.DESCRIPTION = JSON.parse(its.DATA.DESCRIPTION)
                        self.model = its.DATA;
                        self.PREVIEW = its.DATA.DESCRIPTION.PREVIEW
                        self.AFTER = its.DATA.DESCRIPTION.AFTER
                        self.check = true
                    } else {
                        self.model = {
                            NAME: "DAY_NOTICE",
                            TYPE: "SAFE",
                            VALUE: "TIME",
                            DESCRIPTION: {
                                PREVIEW: self.PREVIEW,
                                AFTER: self.AFTER,
                            }
                        }
                        self.check = false
                    }
                }
            })
        },
        submit() {
            let self = this;
            if (!self.check) {
                self.model = {
                    NAME: "DAY_NOTICE",
                    TYPE: "SAFE",
                    VALUE: "TIME",
                    DESCRIPTION: {
                        PREVIEW: self.PREVIEW,
                        AFTER: self.AFTER,
                        PREVIEW_DONE: self.$moment().format("YYYY-MM-DD HH:mm:ss"),
                        AFTER_DONE: self.$moment().format("YYYY-MM-DD HH:mm:ss")
                    }
                }
            }else{
                self.model.DESCRIPTION.PREVIEW=self.PREVIEW
                self.model.DESCRIPTION.AFTER=self.AFTER
            }
            self.model.DESCRIPTION = JSON.stringify(self.model.DESCRIPTION)
            self.sta.loading = true;
            this.whale.remote.getResult({
                url: "/api/School/FX/DictApi/Save",
                data: self.model,
                finally() { self.sta.loading = false; },
                completed: function () {
                    self.$message.success('设置成功！')
                    self.sta.show = false;
                    self.$emit("on-saved");
                }
            })
        }
    },
}
</script>
<style lang="less" scoped></style>