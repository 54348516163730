<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-shield"></i> 安全巡检 | 项目设置</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit({ LEVEL: 1, SORT: 1 })">新增记录</el-button>
            <el-button type="warning" @click="imp(1)">导入主项目</el-button>
            <el-button type="danger" @click="imp(2)">导入子项目</el-button>
            <!-- <form id="fm_export" method="post" action="/api/School/SAFE/Config/Export" target="_blank" style="display: inline-block; margin: 0px 10px">
            <input type="hidden" name="KEY" v-model="grid.sea.KEY" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
            <el-button type="info" @click="time">设置提醒时间</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.Safe_Class_Name" placeholder="请选择类别" clearable @change="filter">
                <el-option v-for="it in type.ls" :key="it.ID" :label="it.Safe_Class_Name" :value="it.Safe_Class_Name" />
              </el-select>

              <el-select v-model="grid.sea.TemplateGuid" placeholder="请选择类型" clearable @change="filter">
                <el-option v-for="it in temp.ls" :key="it.Template_guid" :label="it.Template_name"
                  :value="it.Template_guid" />
              </el-select>


              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px; margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">
              <el-table-column prop="ID" label="ID" width="100" />
              <el-table-column prop="NAME" label="名称" width="300" />
              <el-table-column prop="Safe_Class_Name" label="巡检类别" width="150"></el-table-column>
              <el-table-column prop="TemplateName" label="巡检类型" width="100"></el-table-column>
              <el-table-column prop="SORT" label="排序" width="60" align="center" />
              <el-table-column prop="LOCA" label="位置" width="200" />
              <el-table-column prop="FREQUENCY_TXT" label="周期" width="60" align="center" />
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-share" @click="sonIndex(scope.row)">子项目</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-document" @click="info(scope.row)">详情</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="conf_edit" @on-saved="filters" />
    </div>
    <div>
      <Son ref="son_index" />
    </div>
    <div>
      <Imp ref="conf_imp" @on-saved="filter" :template="template" post_url="/api/School/SAFE/ConfigApi/Import"
        :LEVEL="LEVEL" />
    </div>
    <div>
      <Time ref="conf_time" @on-saved="filter"></Time>
    </div>
    <div>
      <Info ref="conf_info"></Info>
    </div>
  </div>
</template>

<script>
import Edit from "./edit";
import Son from "./son";
// import Imp from "@/components/import"
import Imp from "./imp"
import Wgrid from "@/components/wgrid";
import Time from './time'
import jsFileDownload from 'js-file-download'
import Info from './info.vue'
export default {
  name: "index",
  components: { Wgrid, Edit, Son, Imp, Time, Info },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          PARENT_ID: 0
        },
        ls: [],
        total: 0,
        loading: false,
      },
      LEVEL: 1,
      type: { ls: [] },
      template: '',
      temp: { ls: [] }
    }
  },
  created() {
    this.getType()
    this.getTemp()
    this.getList();
  },
  methods: {
    filter() {
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    filters() {
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getType() {
      let self = this;
      this.whale.remote.getCollection({
        url: '/api/School/SAFE/SafeClassConfig/GetPageList',
        completed(its) {
          console.log('its', its)
          self.type.ls = its
        }
      })
    },
    getTemp() {
      let self = this
      this.whale.remote.getResult({
        url: '/api/School/SAFE/ConfigApi/GetTemplates',
        completed(its) {
          console.log('123', its.DATA)
          self.temp.ls = its.DATA
        }
      })
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/SAFE/ConfigApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    rowEdit(r) {
      this.$refs.conf_edit.init(r);
    },
    info(r) {
      this.$refs.conf_info.init(r)
    },
    rowDel(r) {
      this.whale.delConfirm(() => {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/SAFE/ConfigApi/Delete",
          data: { ID: r.ID },
          completed: function () {
            //self.filter();
            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
          }
        })
      });
    },
    sonIndex(r) {
      this.$refs.son_index.init(r);
    },
    imp(num) {
      if (num == 1) {
        this.template = '/api/files/template/safe_conf_imp_parent.xls'
      } else {
        this.template = '/api/files/template/safe_conf_imp_child.xls'
      }
      this.LEVEL = num
      this.$refs.conf_imp.init();
    },
    time() {
      this.$refs.conf_time.init();

    },
    exportExcel() {
      let self = this;
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('KEY', this.grid.sea.KEY || '')
      this.whale.remote.getExport({
        url: "/api/School/SAFE/Config/Export",
        data: formData,
        completed: function (its) {
          // console.log('exls', its.Data)
          // self.whale.downLoad(its.Data)
          jsFileDownload(its, '导出安全巡检项目设置.xls')
          self.grid.loading = false;
        }
      })
    },
  }
}
</script>
