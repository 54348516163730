<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">子项目列表</span>
      <div class="row">
        <div class="col-md-12">
          <el-button type="primary" @click="rowEdit({LEVEL:2,SORT:1,PARENT_ID: grid.sea.PARENT_ID})">新增子项</el-button>
        </div>
        <div class="col-md-12">
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1">
            <el-table-column prop="NAME" label="名称" width="300" />
            <el-table-column prop="SORT" label="排序" width="60" align="center" />
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </wgrid>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
      </div>
    </el-dialog>
    <div>
      <Edit ref="son_conf_edit" @on-saved="getList" />
    </div>
  </div>
</template>
<script>
  import Wgrid from "@/components/wgrid";
  import Edit from "@/views/safe/conf/edit";
  export default {
    components: { Wgrid, Edit },
    data() {
      return {
        sta: {
          show: false,
          loading: false
        },
        grid: {
          sea: {
            PAGE_SIZE: 0,
            PAGE_INDEX: 0,
            PARENT_ID: 0
          },
          ls: [],
          total: 0,
          loading: false
        }
      }
    },
    methods: {
      init(model) {
        this.sta = { show: true, loading: false };
        this.grid.sea.PARENT_ID = model.ID;
        this.getList();
      },
      getList() {
        let self = this;
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/SAFE/ConfigApi/GetList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;
          }
        })
      },
      rowEdit(r) {
        this.$refs.son_conf_edit.init(r);
      },
      rowDel(r) {
        this.whale.delConfirm(() => {
          let self = this;
          this.whale.remote.getResult({
            url: "/api/School/SAFE/ConfigApi/Delete",
            data: { ID: r.ID },
            completed: function () {
              self.getList();
            }
          })
        });
      },
    }
  }
</script>