var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"visible":_vm.sta.show},on:{"update:visible":function($event){return _vm.$set(_vm.sta, "show", $event)}}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("提醒时间设置（每日安全巡检）")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_c('b',[_vm._v("巡检前提醒时间")])]),_c('br'),_c('el-time-select',{attrs:{"picker-options":{
        start: '05:00',
        step: '00:15',
        end: '23:00'
    },"placeholder":"选择时间"},model:{value:(_vm.PREVIEW),callback:function ($$v) {_vm.PREVIEW=$$v},expression:"PREVIEW"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_c('b',[_vm._v("过期未巡检提醒时间")])]),_c('br'),_c('el-time-select',{attrs:{"picker-options":{
        start: '05:00',
        step: '00:15',
        end: '23:00'
    },"placeholder":"选择时间"},model:{value:(_vm.AFTER),callback:function ($$v) {_vm.AFTER=$$v},expression:"AFTER"}})],1)])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.sta.loading),expression:"sta.loading"}],attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"icon":"el-icon-close"},on:{"click":function($event){_vm.sta.show = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"icon":"el-icon-check","type":"success"},on:{"click":_vm.submit}},[_vm._v("确认")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }