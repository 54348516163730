<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增/修改 记录</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>项目名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
          <div class="form-group">
            <label><b>排序号</b></label>
            <el-input type="text" v-model="model.SORT" />
          </div>
          <div class="form-group" v-if="model.LEVEL != 2">
            <label><b>位置</b></label>
            <el-input type="text" v-model="model.LOCA" />
          </div>
          <div class="form-group" v-if="model.LEVEL != 2">
            <label><b>类别</b></label>
            <el-select v-model="grid.val" placeholder="请选择类别" clearable style="display: block;" @change="changeType">
              <el-option v-for="it in grid.ls" :key="it.ID" :label="it.Safe_Class_Name" :value="it.ID" />
            </el-select>
          </div>
          <div class="form-group">
            <label><b>类型</b></label>
            <el-select v-model="model.TemplateGuid" placeholder="请选择类型" clearable style="display: block;"
              @change="changeTemp">
              <el-option v-for="it in temp.ls" :key="it.Template_guid" :label="it.Template_name"
                :value="it.Template_guid" />
            </el-select>
            <div v-if="model.TemplateGuid && !model.ID">
              <span>子项目：</span>
              <!-- <template v-if="model.ID">
                <span v-for="(cit, index) in temp.lss" :key="index">
                  {{ cit }} <span v-if="index != temp.lss.length - 1">/</span>
                </span>
              </template> -->

              <template v-if="!model.ID">
                <span v-for="(cit, index) in temp.lss" :key="index">
                  {{ cit.Name }} <span v-if="index != temp.lss.length - 1">/</span>
                </span>
              </template>
            </div>
          </div>

          <div class="form-group" v-if="model.LEVEL != 2">
            <label><b>周期</b></label>
            <el-select v-model="model.FREQUENCY" placeholder="请选择周期" clearable style="display: block;" :disabled="true">
              <el-option v-for="it in date" :key="it.VALUE" :label="it.NAME" :value="it.VALUE" />
            </el-select>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
      model: {
        TemplateGuid: ''
      },
      date: [{ NAME: '日', VALUE: 'DAY' }, { NAME: '周', VALUE: 'WEEK' }, { NAME: '半月', VALUE: 'HALFMONTH' }, { NAME: '月', VALUE: 'MONTH' }],
      temp: { val: '', ls: [], lss: [] },
      grid: { ls: [], val: '' }
    }
  },
  methods: {
    init(model) {
      this.sta = { show: true, loading: false }
      this.model = JSON.parse((JSON.stringify(model)));
      console.log('mmmmm', this.model)
      this.grid.val = this.model.Safe_Class_Id
      if (this.model.ID) {
        this.temp.lss = this.model.TemplateChildrenName
      }
      // if (model==undefined || model==null) this.model={LEVEL:1,SORT:1};
      // else {
      //
      // }
      this.getType()
      this.getTemp()
    },
    getType() {
      let self = this;
      this.whale.remote.getCollection({
        url: '/api/School/SAFE/SafeClassConfig/GetPageList',
        completed(its) {
          console.log('its', its)
          self.grid.ls = its
        }
      })
    },
    changeType(e) {
      // this.model.Safe_Class_Name = this.grid.val
      this.model.Safe_Class_Id = e
      let self = this
      this.grid.ls.map(i => {
        if (i.ID == e) {
          self.model.FREQUENCY = i.Frequency
          self.model.Safe_Class_Name = i.Safe_Class_Name
        }
      })
    },
    getTemp() {
      let self = this
      this.whale.remote.getResult({
        url: '/api/School/SAFE/ConfigApi/GetTemplates',
        completed(its) {
          console.log('123', its.DATA)
          self.temp.ls = its.DATA
        }
      })
    },
    changeTemp(e) {
      console.log(e)
      if (this.model.ID) {
        return
      } else {
        let self = this
        self.temp.ls.map(item => {
          if (item.Template_guid == e) {
            self.temp.lss = item.detailsDto
          }
        })
      }
    },
    submit() {
      let self = this;
      if (self.model.LEVEL == 1 && !self.model.NAME) {
        self.$message.warning('请填写项目名称！')
        return
      }
      if (self.model.LEVEL == 1 && !self.model.FREQUENCY) {
        self.$message.warning('请选择类别')
        return
      }
      // if (self.model.LEVEL == 1 && !self.model.TemplateGuid) {
      //   self.$message.warning('请选择模板')
      //   return
      // }

      let data = {
        CREATED_DT: self.model.CREATED_DT,
        FREQUENCY: self.model.FREQUENCY,
        FREQUENCY_TXT: self.model.FREQUENCY_TXT,
        GUID: self.model.GUID,
        ID: self.model.ID,
        LEVEL: self.model.LEVEL,
        LOCA: self.model.LOCA,
        NAME: self.model.NAME,
        PARENT_ID: self.model.PARENT_ID,
        SCH_ID: self.model.SCH_ID,
        SONS: self.model.SONS,
        SORT: self.model.SORT,
        Safe_Class_Id: self.model.Safe_Class_Id,
        Safe_Class_Name: self.model.Safe_Class_Name,
        TemplateGuid: self.model.TemplateGuid
      }

      self.sta.loading = true;
      this.whale.remote.getResult({
        url: "/api/School/SAFE/ConfigApi/Save",
        data: data,
        finally() { self.sta.loading = false; },
        completed: function () {
          self.sta.show = false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
